import {lazy} from 'react';
import i18n from '../../i18n';

import {
    BookOutlined,
    UnorderedListOutlined,
    UserSwitchOutlined,
    MergeCellsOutlined,
    TeamOutlined,
    LoginOutlined,
    MacCommandOutlined,
    FontColorsOutlined,
    FileImageOutlined,
    UserAddOutlined,
    KeyOutlined,
    UsergroupAddOutlined
} from '@ant-design/icons'

const options = [
    {
        key: Math.random(),
        path: '/AppVariables',
        component: lazy(() => import('../appVariables/List')),
        exact: true,
        name: i18n.t('appVariables'),
        icon: FontColorsOutlined,
        render: true
    },
    {
        key: Math.random(),
        path: '/CompanyProducts',
        component: lazy(() => import('../companyProduct/List')),
        exact: true,
        name: i18n.t('Company Products'),
        icon: UserSwitchOutlined,
        render: true
    },
    {
        key: Math.random(),
        path: '/Staff',
        component: lazy(() => import('../staffs/List')),
        exact: true,
        name: i18n.t('Staffs'),
        icon: UsergroupAddOutlined,
        render: true
    },
    {
        key: Math.random(),
        path: '/Doctors',
        component: lazy(() => import('../doctors/List')),
        exact: true,
        name: i18n.t('Doctors'),
        icon: UserAddOutlined,
        render: true
    },
    {
        key: Math.random(),
        exact: true,
        name: i18n.t('Categories'),
        render: true,
        icon: UnorderedListOutlined,
        subRoutes: [
            {
                key: Math.random(),
                path: '/Categories',
                component: lazy(() => import('../category/List')),
                exact: true,
                name: i18n.t('Categories'),
                icon: UnorderedListOutlined,
                render: true
            },
            {
                key: Math.random(),
                path: '/Contents',
                component: lazy(() => import('../categoryContent/List')),
                exact: true,
                name: i18n.t('Contents'),
                icon: FontColorsOutlined,
                render: true
            },
            {
                key: Math.random(),
                path: '/Galleries',
                component: lazy(() => import('../categoryGallery/List')),
                exact: true,
                name: i18n.t('Galleries'),
                icon: FileImageOutlined,
                render: true
            }
        ]
    },
    {
        key: Math.random(),
        path: '/Dental Blueprints',
        component: lazy(() => import('../labDentalBlueprint/List')),
        exact: true,
        name: i18n.t('DentalBlueprints'),
        icon: MergeCellsOutlined,
        render: true
    },
    {
        key: Math.random(),
        exact: true,
        name: i18n.t('Academy'),
        render: true,
        icon: BookOutlined,
        subRoutes: [
            {
                key: Math.random(),
                path: '/Speakers',
                component: lazy(() => import('../academySpeaker/List')),
                exact: true,
                name: i18n.t('Speakers'),
                icon: TeamOutlined,
                render: true
            },
            {
                key: Math.random(),
                path: '/Courses',
                component: lazy(() => import('../academyCourse/List')),
                exact: true,
                name: i18n.t('Courses'),
                icon: TeamOutlined,
                render: true
            }
        ]
    },
    {
        key: Math.random(),
        exact: true,
        name: i18n.t('Clinic'),
        render: true,
        icon: MacCommandOutlined,
        subRoutes: [
            {
                key: Math.random(),
                path: '/Team',
                component: lazy(() => import('../clinicTeam/List')),
                exact: true,
                name: i18n.t('Team'),
                icon: LoginOutlined,
                render: true
            },
            {
                key: Math.random(),
                path: '/Services',
                component: lazy(() => import('../clinicService/List')),
                exact: true,
                name: i18n.t('Services'),
                icon: MacCommandOutlined,
                render: true
            },
        ]
    },
    {
        key: Math.random(),
        path: '/Form',
        component: lazy(() => import('../form/List')),
        exact: true,
        name: i18n.t('Form Registration'),
        icon: KeyOutlined,
        render: true
    },
];
export default options;
