/* eslint-disable max-len */
import React from 'react';
import {
    Row, Col, Form, Input, Button,
    message, Select, Card, Space, notification,
} from 'antd';
import {
    ArrowRightOutlined,
    LockFilled,
    UserOutlined,
} from '@ant-design/icons';
import {observer, inject} from 'mobx-react';
import {withTranslation} from 'react-i18next';

import i18n from '../../i18n';
import logo from '../../assets/images/logo-bg.png';
import {postRequest} from '../../helpers/superagentAPI';

import './login.css';

const {Option} = Select;


@inject('tokenStore', 'userStore')
@observer
class Login extends React.Component {
    constructor() {
        super();
        this.initialState = () => ({
            loading: false,
            isUserValid: false,
            formValues: undefined,
        });
        this.state = this.initialState();

        this.onSubmit = async (values) => {
            try{
                const {username,password} = values
                this.setState({
                    loading:true
                })
                let user = await postRequest('User/Login',{
                    username,
                    password
                })
                this.props.userStore.setUser(user.user);
                this.props.tokenStore.setToken(user.token.token);
                location.reload()
            }
            catch(error){}
            finally {
                this.setState({
                    loading:false
                })
            }
        };

        this.onGoBack = () => {
            window.location.reload();
        };

        this.handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                this.onSubmit();
            }
        };


        this.formRef = React.createRef();
    }

    componentDidMount() {
        if (i18n.language === 'en-US') {
            i18n.changeLanguage('en');
            window.location.reload();
        }
    }

    render() {
        const {loading, isUserValid, otpLoadingBtn} = this.state;
        return (
            <>
                <Row align={'center'} justify={'middle'} style={{
                    height: '100vh'
                }}>
                    <Col span={24} md={10}>
                        <Card className="login-card font-calss darkBlueBg" width={'50%'}>
                            <div>
                                <Row justify="center" gutter={[0, 10]}>
                                    <Col>
                                        <p className="login-card-title font-calss">
                                            <img style={{width: '100%'}} src={logo} alt="logo"/>
                                        </p>
                                    </Col>
                                </Row>
                                <Row align={'center'}>
                                    <Col span={24} md={20}>
                                        <Form
                                            name="basic"
                                            onFinish={this.onSubmit}
                                            autoComplete="off"
                                        >
                                            <Form.Item
                                                label={<label style={{ color: "white" }}>{i18n.t('Username')}</label>}
                                                name="username"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: i18n.t('Input is required'),
                                                    },
                                                ]}
                                            >
                                                <Input/>
                                            </Form.Item>

                                            <Form.Item
                                                label={<label style={{ color: "white" }}>{i18n.t('Password')}</label>}
                                                name="password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: i18n.t('Input is required'),
                                                    },
                                                ]}
                                            >
                                                <Input.Password/>
                                            </Form.Item>
                                            <Row align={'center'}>
                                                <Col span={6} style={{
                                                    display:'flex',
                                                    alignItems:'center',
                                                    justifyContent:'center'
                                                }}>
                                                    <Button type="primary" htmlType="submit" className={'yellowBg'} loading={loading}>
                                                        {i18n.t('Sign in')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                                <Row justify="center">
                                    <Col>
                                        <p className="login-card-subtitle font-calss" style={{fontSize: 12}}>
                                            Guide
                                            {' '}
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://lucid-source.com"
                                                style={{fontFamily: 'Cuprumsemi'}}
                                            >
                                                <span style={{fontSize: 16}}>{i18n.t('Lucid Source')}</span>
                                            </a>
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

export default withTranslation()(Login);
