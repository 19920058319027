/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
import React, {Suspense} from 'react';
import {observer, inject} from 'mobx-react';
import {
    Layout, Menu, Button, Avatar, Dropdown, Modal,
    Popover, Col, Row, Badge, Form, Input
} from 'antd';
import {
    TeamOutlined,
    UserOutlined,
    DownOutlined,
    PoweroffOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    NotificationOutlined
} from '@ant-design/icons';
import {
    Switch, Route, Link, withRouter,
} from 'react-router-dom';
import {withTranslation} from 'react-i18next';

import i18n from '../../i18n';
import store from '../../store';
import routes from './dashboardRoutes';
import Loading from '../basic/Loading';
import getAgentInstance from '../../helpers/superagent';
import {postRequest} from '../../helpers/superagentAPI'
import largeLogo from '../../assets/images/logo-bg.png';
import smalLogo from '../../assets/images/logo-bg.png';

import './dashboard.css';

const superagent = getAgentInstance();
const {
    Header, Content, Footer, Sider,
} = Layout;
const rootSubmenuKeys = ['/', '2', '6', '4', '7'];

@inject('userStore', 'tokenStore')
@observer
class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.initialState = () => ({
            loading: false,
            collapsed: false,
            defualtMenu: 'visitlist',
            UIFlags: {},
            brandingLogo: '',
            brandingColor: '',
            countRent: 0,
            countSale: 0,
            openKeys: [],
            clients: [],
            pushingNotifications: false
        });
        this.state = this.initialState();

        this.routeClicked = (history, to) => {
            history.push(to);
        };
        this.toggleSidebar = () => {
            this.setState((prevState) => ({
                collapsed: !prevState.collapsed,
            }));
        };
        this.logout = () => {
            localStorage.removeItem(process.env.REACT_APP_USER_LOCALSTORAGE_NAME)
            localStorage.removeItem(process.env.REACT_APP_TOKEN_LOCALSTORAGE_NAME)
            location.reload()
        };

        this.warning = () => {
            const scope = this;
            Modal.confirm({
                centered: true,
                title: i18n.t('Logout Of The System?'),
                content: '',
                okText: i18n.t('Yes'),
                cancelText: i18n.t('No'),
                onOk() {
                    scope.logout();
                },
                onCancel() {
                },
            });
        };
    }

    render() {
        const {location, userStore, history} = this.props;
        const {
            collapsed,
            loading,
            brandingLogo,
            brandingColor,
            openKeys,
            pushingNotifications
        } = this.state;
        const {pathname} = location;
        const {value} = userStore;

        let sectionName;
        if (pathname === '/') {
            sectionName = i18n.t('Dashboard');
        }

        const menu = (
            <Menu style={{top: '10px'}}>
                {/*<Menu.Item*/}
                {/*    onClick={() => {*/}
                {/*        return*/}
                {/*    }}*/}
                {/*    icon={(*/}
                {/*        <UserOutlined*/}
                {/*            style={{*/}
                {/*                color: brandingColor !== '' ? brandingColor : '#65DFC2',*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*>*/}
                {/*    {i18n.t('My Profile')}*/}
                {/*</Menu.Item>*/}
                {/*<Menu.Item*/}
                {/*    onClick={() => {*/}
                {/*        return*/}
                {/*    }}*/}
                {/*    icon={(*/}
                {/*        <TeamOutlined*/}
                {/*            style={{*/}
                {/*                color: brandingColor !== '' ? brandingColor : '#65DFC2',*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    )}*/}
                {/*>*/}
                {/*    {i18n.t('User Profile')}*/}
                {/*</Menu.Item>*/}
                {/*<Menu.Divider/>*/}
                <Menu.Item
                    onClick={this.warning}
                    icon={(
                        <PoweroffOutlined
                            style={{
                                color: brandingColor !== '' ? brandingColor : '#65DFC2',
                            }}
                        />
                    )}
                >
                    {i18n.t('Logout')}
                </Menu.Item>
            </Menu>
        );

        const sendNotificationToAllUsersFormFinish = async (values) => {
            try {
                this.setState({
                    pushingNotifications: true
                })
                const {title, ar_title, kr_title, description, ar_description, kr_description} = values
                await postRequest('/admin/notifications/push', {
                    title,
                    ar_title,
                    kr_title,
                    description,
                    ar_description,
                    kr_description
                })
            } catch (error) {

            } finally {
                this.setState({
                    pushingNotifications: false
                })
            }
        }

        const onOpenChange = (keys) => {
            const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
            if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                this.setState({openKeys: keys});
            } else {
                this.setState({openKeys: latestOpenKey ? [latestOpenKey] : []});
            }
        };

        return (
            <>
                <style>
                    {`

          .sider_toggler_btn {
            position: static !important;
          top: 0 !important;
          left: 0px !important;
          z-index: 2;
          }

          .sider_menu_links.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
              background-color: ${brandingColor}1a;
          }

          .sider_menu_links .ant-menu-item:hover {
              background-color: ${brandingColor}1a;
          }

          .sider_menu_links .ant-menu-item-selected::before {
              content: '';
              width: 4px;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background: ${brandingColor};
          }
        `}
                </style>
                <div
                    style={{
                        width: '100%',
                        height: '100vh',
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: !loading ? 'none' : 'flex',
                    }}
                >
                    <Loading visible={loading} tip={i18n.t('Loading Kulshi Data...')}/>
                </div>
                <Layout
                    style={{
                        minHeight: '100vh',
                        display: loading ? 'none' : undefined,
                    }}
                >
                    <Sider
                        theme="light"
                        className="layout_sider darkBlueBg"
                        width={240}
                        collapsed={collapsed}
                        style={{
                            height: '100vh',
                            left: i18n.language === 'en' ? 0 : null,
                            right: i18n.language !== 'en' ? 0 : null,
                            position: 'fixed',
                            overflow: 'auto',
                        }}
                    >
                        <div className={collapsed ? 'logo_small' : 'logo'}>
                            {collapsed ? (
                                <img
                                    alt="logo"
                                    style={{width: 65}}
                                    src={brandingLogo !== '' ? brandingLogo : smalLogo}
                                />
                            ) : (
                                <img
                                    alt="logo"
                                    style={{width: 185}}
                                    src={brandingLogo !== '' ? brandingLogo : largeLogo}
                                />
                            )}
                        </div>
                        <Menu
                            openKeys={openKeys}
                            onOpenChange={onOpenChange}
                            mode="inline"
                            className="sider_menu_links"
                            selectedKeys={[location.pathname]}
                        >
                            {routes.map(r => {
                                return (r.render && (
                                    (
                                        (!r.subRoutes ? (
                                            <Menu.Item key={r.path}>
                                                {r.icon ? React.createElement(r.icon, {}) : (<></>)}
                                                <span>{i18n.t(r.name)}</span>
                                                <Link to={r.path}/>
                                            </Menu.Item>
                                        ) : (
                                            <Menu.SubMenu
                                                key={r.key}
                                                title={i18n.t(r.name)}
                                                icon={r.icon ? React.createElement(r.icon, {}) : (<></>)}
                                            >
                                                {r.subRoutes.map(subRoute => {
                                                    return (subRoute.render && (
                                                        <Menu.Item key={subRoute.path}>
                                                            {subRoute.icon ? React.createElement(subRoute.icon, {}) : (<></>)}
                                                            <span>{i18n.t(subRoute.name)}</span>
                                                            <Link to={subRoute.path}/>
                                                        </Menu.Item>
                                                    ))
                                                })
                                                }
                                            </Menu.SubMenu>
                                        ))
                                    )
                                ))
                            })}
                        </Menu>
                    </Sider>
                    {i18n.language === 'en' ? (
                        <Layout
                            className="site-layout"
                            style={{
                                transition: 'all 0.3s',
                                marginLeft: collapsed === false ? 240 : 80,
                                marginRight: 0,
                            }}
                        >
                            <Header
                                className="site-layout-background yellowBg"
                                style={{padding: 0, display: 'inherit', position: 'relative'}}
                            >
                                <Col>
                                    <div className="sider_toggler_btn">
                                        <Button
                                            type="link"
                                            icon={
                                                collapsed ? (
                                                    <MenuUnfoldOutlined style={{color: '#1f2532'}}/>
                                                ) : (
                                                    <MenuFoldOutlined style={{color: '#1f2532'}}/>
                                                )
                                            }
                                            onClick={this.toggleSidebar}
                                            style={{
                                                textAlign: 'start',
                                                paddingLeft: 10,
                                                paddingRight: undefined,
                                            }}
                                        />
                                    </div>
                                </Col>
                                <div className="main_header_container">
                                    <div className="section_title">{sectionName || ''}</div>
                                    <div className="header_avatar">
                                        <Row gutter={[10, 0]}>
                                            <Col>
                                                <Dropdown
                                                    overlay={menu}
                                                    trigger="click"
                                                    placement="bottomRight"
                                                >
                                                  <span className="ant-dropdown-link pointer header_display_name">
                                                    {value?.name_en?.toLowerCase()}
                                                      {' '}
                                                      <DownOutlined style={{marginLeft: '5px'}}/>
                                                  </span>
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Header>
                            <Content style={{margin: '16px 16px', marginBottom: 0}}>
                                <div
                                    className="site-layout-background"
                                    style={{
                                        padding: '8px 8px 0',
                                        minHeight: 500,
                                        backgroundColor: 'transparent',
                                    }}
                                >
                                    <Suspense fallback={<Loading/>}>
                                        <Switch>
                                            {routes.map((route) => (!route.subRoutes ? (<Route
                                                exact={route.exact}
                                                key={route.key}
                                                path={`${route.path}`}
                                            >
                                                <route.component/>
                                            </Route>) : (route.subRoutes.map(subRoute => (<Route
                                                exact={subRoute.exact}
                                                key={subRoute.key}
                                                path={`${subRoute.path}`}
                                            >
                                                <subRoute.component/>
                                            </Route>)))))}
                                        </Switch>
                                    </Suspense>
                                </div>
                            </Content>
                            <Footer style={{textAlign: 'center'}}>
                                <span>{i18n.t('Designed and Developed By')}</span>
                                &nbsp;
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://lucid-source.com"
                                    title="Lucid Source"
                                >
                                    {i18n.t('Lucid Source')}
                                </a>
                            </Footer>
                        </Layout>
                    ) : (
                        <Layout
                            className="site-layout"
                            style={{
                                transition: 'all 0.3s',
                                marginRight: collapsed === false ? 240 : 80,
                                marginLeft: 0,
                            }}
                        >
                            <Header
                                className="site-layout-background yellowBg"
                                style={{padding: 0, display: 'inherit', position: 'relative'}}
                            >
                                <Col
                                    span={1}
                                    style={{
                                        textAlign: 'start',
                                        paddingLeft: undefined,
                                        paddingRight: 10,
                                    }}
                                >
                                    <Button
                                        type="link"
                                        icon={
                                            collapsed ? (
                                                <MenuUnfoldOutlined style={{color: '#1f2532'}}/>
                                            ) : (
                                                <MenuFoldOutlined style={{color: '#1f2532'}}/>
                                            )
                                        }
                                        onClick={this.toggleSidebar}
                                    />
                                </Col>
                                <div className="main_header_container">
                                    <div className="section_title">{sectionName || ''}</div>
                                    <div className="header_avatar">
                                        <Row gutter={[10, 0]}>
                                            <Col>
                                                <Dropdown
                                                    overlay={(
                                                        <Menu>
                                                            <Menu.Item
                                                                onClick={() => {
                                                                    i18n.changeLanguage('ar');
                                                                    window.location.reload();
                                                                }}
                                                                key="4"
                                                                style={{fontFamily: 'arabic_regular'}}
                                                            >
                                                                عربي
                                                            </Menu.Item>
                                                            <Menu.Item
                                                                onClick={() => {
                                                                    i18n.changeLanguage('en');
                                                                    window.location.reload();
                                                                }}
                                                                key="1"
                                                                style={{fontFamily: 'CuprumRegular'}}
                                                            >
                                                                English
                                                            </Menu.Item>
                                                        </Menu>
                                                    )}
                                                >
                                                    <Button className="br-10_h-40" type="default">
                                                        {i18n.language === 'ar' ? 'العربية' : null}
                                                        {' '}
                                                        {i18n.language === 'en' ? 'English' : null}
                                                        <DownOutlined/>
                                                    </Button>
                                                </Dropdown>
                                            </Col>
                                            <Col>
                                                <Avatar shape="square" icon={<UserOutlined/>}/>
                                            </Col>
                                            <Col style={{marginLeft: '10px'}}>
                                                <Dropdown
                                                    overlay={menu}
                                                    trigger="click"
                                                    placement="bottomRight"
                                                >
                          <span className="ant-dropdown-link pointer header_display_name">
                            {value?.name_en?.toLowerCase()}
                              {' '}
                              <DownOutlined style={{marginRight: '5px'}}/>
                          </span>
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Header>
                            <Content style={{margin: '16px 16px', marginBottom: 0}}>
                                <div
                                    className="site-layout-background"
                                    style={{
                                        padding: '8px 8px 0',
                                        minHeight: 500,
                                        backgroundColor: 'transparent',
                                    }}
                                >
                                    <Suspense fallback={<Loading/>}>
                                        <Switch>
                                            {routes.map((route) => (
                                                !route.subRoutes ? (<Route
                                                        exact={route.exact}
                                                        key={route.key}
                                                        path={`${route.path}`}
                                                    >
                                                        <route.component/>
                                                    </Route>
                                                ) : (
                                                    route.subRoutes.map((subRoute) => (
                                                        <Route
                                                            exact={subRoute.exact}
                                                            key={subRoute.key}
                                                            path={`${subRoute.path}`}
                                                        >
                                                            <subRoute.component/>
                                                        </Route>
                                                    ))
                                                )
                                            ))}
                                        </Switch>
                                    </Suspense>
                                </div>
                            </Content>
                            <Footer style={{textAlign: 'center'}}>
                                <span>{i18n.t('Designed and Developed By')}</span>
                                &nbsp;
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://lucid-source.com"
                                    title="Lucid Source"
                                >
                                    {i18n.t('Lucid Source')}
                                </a>
                            </Footer>
                        </Layout>
                    )}
                </Layout>
            </>
        );
    }
}

export default withTranslation()(
    withRouter((props) =>
        <Dashboard {...props} />
    ),
);
